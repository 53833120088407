<template>
  <form-validation @submit="submitForm">
    <b-row>
      <b-col sm="12" md="8">
        <my-card :isloading="cardloading" :title="cardtitle">
          <template slot="body">
            <b-row>
              <b-col sm="12">
                <form-select :filter-id="idBranchArray" :disabled="formStatus!=0" ref="branchSelect" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" @value-changed="branch_name = $event.label" :rules="{required: true}" v-model="id_branch" label="Cabang Asal"></form-select>
              </b-col>
              <b-col sm="12" md="8">
                <form-date :disabled="!cP(105)||formStatus==3" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12">
                <form-select :disabled="formStatus!=0" ref="branch2Select" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" @value-changed="branch_sender_name = $event.label" :rules="{required: true}" v-model="id_branch_sender" label="Cabang Tujuan"></form-select>
              </b-col>
              <b-col sm="12">
                <form-textarea :rules="{required: false}" v-model="note" label="Catatan"></form-textarea>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
      <b-col v-if="$route.params.id" sm="12" md="4">
        <my-card title="Informasi User">
          <template slot="body">
            <table class="w-100">
              <tbody>
                <tr>
                  <td class="font-weight-bold">Di Input Oleh :</td>
                  <td class="text-right">{{inputInfo.user_create}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Di Update Oleh :</td>
                  <td class="text-right">{{inputInfo.user_update}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Di Approve Oleh :</td>
                  <td class="text-right">{{inputInfo.user_approval}}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </my-card>
      </b-col>
      <b-col sm="12">
        <my-card :isloading="cardloading" title="Detail Item">
          <template slot="body">
            <div>
              <b-row v-for="(tr,i) in details" :key="i">
                <b-col sm="12" md="2">
                  <form-select :disabled="formStatus!=0&&!lodash.isNull(tr.id_request_approval)" size="sm" :ref="`itemSelect-${i}`" url="v1/item_select2" @value-changed="rowItemChange($event,i)" :rules="{required: true}" v-model="tr.id_item" :label="`Item ke ${i+1}`"></form-select>
                </b-col>
                <b-col sm="4" md="2">
                  <form-input-number :rules="{ required: true }" disabled size="sm" v-model="tr.qty_approval" label="Qty Persetujuan"></form-input-number>
                </b-col>
                <b-col sm="6" md="2">
                  <form-input-number :rules="{ required: true }" disabled size="sm" v-model="tr.qty_out" label="Qty yg sudah keluar"></form-input-number>
                </b-col>
                <b-col sm="6" md="2">
                  <form-select size="sm" :disabled="formStatus!=0&&!lodash.isNull(tr.id_request_approval)" :ref="`unitSelect-${i}`" url="v1/multi_unit_select2" :queryparams="{id_item: tr.id_item}" @value-changed="rowUnitChange($event,i)" :rules="{required: true}" v-model="tr.id_unit" label="Stn"></form-select>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number :disabled="formStatus==3" :rules="{ required: true, minimal:[0] }" size="sm" v-model="tr.qty" label="Qty"></form-input-number>
                </b-col>
                <b-col sm="12" md="2">
                  <form-input size="sm" :disabled="formStatus==3" v-model="tr.note" label="Catatan"></form-input>
                </b-col>
                <b-col v-if="formStatus!=3">
                  <b-button v-if="lodash.isNull(tr.id_request_approval) && isPusat" class="mt-2" block variant="danger" @click="deleteRow(i)" size="sm">
                    <feather-icon icon="TrashIcon"></feather-icon> Hapus
                  </b-button>
                </b-col>
                <b-col sm="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col sm="12" md="2" class="mt-2" order-sm="1" order-md="0">
                <b-button v-if="isPusat&&formStatus!=3" block variant="primary" @click="addRow">
                  <feather-icon icon="PlusIcon"></feather-icon> Tambah Detail
                </b-button>
              </b-col>
              <b-col sm="12" offset-md="7" md="3">
                <b-row>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalQty" label="Total Qty" :rules="{required: true}"></form-input-number>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="mt-4" v-if="formStatus!=3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'transferouts'}"></button-back>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
    </b-row>
  </form-validation>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import {BButton} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components:{
    FormDate,
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
    BButton,
  },
  data(){
    return {
      lodash: _,
      isloading: false,
      cardloading: false,
      cardtitle: "",
      status: "",
      id: this.$route.params.id||null,
      id_branch: null,
      branch_name: "",
      request_code: "",
      date: this.$moment().format(),
      id_branch_sender: null,
      branch_sender_name: "",
      total: 0,
      note: "",
      details : [],
      inputInfo: {}
    }
  },
  computed:{
    totalQty(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total += parseFloat(e.qty)
      }
      return total
    },
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      if(meta.isPart) return 2
      if(meta.isShow) return 3
      else return 0
    },
  },
  methods:{
    ...mapActions({
      dispatchStore: 'transferouts/store',
      dispatchShow: 'transferouts/show',
      dispatchUpdate: 'transferouts/update',
    }),
    async getData(){
      this.cardloading = true
      if(this.id){
        const data = await this.dispatchShow(this.id)
        const {user_create,user_update,user_approval} = data
        this.inputInfo = {user_create,user_update,user_approval}
        this.request_code = data.request_code
        this.status = data.status
        if(data.status=="Disetujui Pusat"||data.status=="Transfer Sebagian") this.cardtitle = data.request_code
        else this.cardtitle = data.code
        this.code = data.code
        this.id_branch = data.id_branch
        this.branch_name = data.branch_name
        if(this.formStatus==2||data.status=='Disetujui Pusat') this.date = this.$moment().format()
        else this.date = data.date
        this.note = data.note
        this.id_branch_sender = data.id_branch_sender
        this.branch_sender_name = data.branch_sender_name
        const details = data.transfer_out_details.map(d => {
          let res = {}
          res.id_request_approval = d.id_request_approval
          res.id_item = d.id_item
          res.name_item = d.name_item
          res.id_unit = d.id_unit
          res.name_unit = d.name_unit
          res.note = d.note
          res.qty_out = 0
          res.qty_approval = d.stock_in_request_approval ? d.stock_in_request_approval.qty : 0
          for (let i = 0; i < d.qty_already_out.length; i++) {
            const e = d.qty_already_out[i];
            res.qty_out+= e.qty_already_out
          }
          res.qty = this.formStatus==1 ? d.qty : (res.qty_approval-res.qty_out).toFixed(2)
          return res
        })
        this.details = details
        if(data.id_branch) this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
        if(data.id_branch_sender) this.$refs.branch2Select.initOption([{value:data.id_branch_sender,label:data.branch_sender_name}])

        for (let i = 0; i < details.length; i++) {
          const e = details[i];
          this.$nextTick(() => {
            this.$refs[`itemSelect-${i}`][0].initOption([{value:e.id_item,label:e.name_item}])
            this.$refs[`unitSelect-${i}`][0].initOption([{value:e.id_unit,label:e.name_unit}])
          })
        }
      } else {
        const defaults = this.$store.state.auth
        this.$refs.branch2Select.initOption(defaults.defaultbranches)
        this.id_branch_sender = defaults.profile.id_branch
        this.branch_sender_name = defaults.profile.branch_name
        this.addRow()
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,request_code,id_branch,date,branch_name,id_branch_sender,branch_sender_name,note,details} = this
      let params = {id,request_code,id_branch,date,branch_name,id_branch_sender,branch_sender_name,note,details}
      params.total = this.totalQty
      params.id_transfer_before = null
      if (this.formStatus==2) {
        delete params.id
        params.id_transfer_before = id
      }
      if(this.totalQty<=0) {
        this.isloading = false
        return this.toastWarning("Maaf", "Jumlah total item yang anda masukkan 0!")
      }
      try {
        if(_.includes([0,2],this.formStatus)) await this.dispatchStore(params)
        else await this.dispatchUpdate(params)
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'transferouts'})
        this.isloading = false
      } catch (error) {
        this.isloading = false
        if(error.response.data.error.err_stok) this.notStockNotification(error.response.data)
        else this.handleError(error)
      }
    },
    addRow(){
      const defaultrow = {
          id_request_approval: null,
          id_item: null,
          name_item: "",
          qty_approval: 0,
          qty: 0,
          qty_out: 0,
          id_unit: null,
          name_unit: "",
          note: ""
      }
      let data = JSON.parse( JSON.stringify(defaultrow) )
      this.details.push(data)
    },
    deleteRow(row){
      this.details.splice(row,1)
    },
    maximalValidation({qty_approval, qty_out}){
      if(this.formStatus==0) return 99999999
      else return qty_approval-qty_out
    },
    rowItemChange(e,row){
      let tr = this.details[row]
      tr.name_item = e.label
      tr.id_unit = null
    },
    rowUnitChange(e,row){
      let tr = this.details[row]
      tr.name_unit = e.label
    },
    notStockNotification({error}){
      let lists = ``
      for (let i = 0; i < error.err_stok.length; i++) {
        const e = error.err_stok[i];
        lists += `
        <tr>
          <td><h6>${e.name}</h6></td>
          <td><h6>${e.stok.toFixed(2)} ${e.unit}</h6></td>
        </tr>
        `
      }
      this.$swal({
        title:`Peringatan`,
        html:`
          <h6>Jumlah Stok Tidak Mencukupi!</h6>
          <table style="width:100%;" class="mt-1 mb-1">
            <thead>
              <tr>
                <th>Nama</th>
                <th>Stok Sistem</th>
              </tr>
            </thead>
            <tbody>
            ${lists}
            </tbody>
          </table>
          ${this.isPusat ? '':'<h6>Apakah anda ingin merealisasikan sebagian sesuai dengan stok yang tersedia di sistem ?</h6>'}
        `,
        icon:`warning`,
        showCancelButton: !this.isPusat,
        cancelButtonText:`Tidak`,
        confirmButtonText:`Ya`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.value){
          if (!this.isPusat) {
            const avails = error.err_stok
            let details = JSON.parse(JSON.stringify(this.details))
            for (let x = 0; x < avails.length; x++) {
              const n = avails[x];
              let row = _.findIndex(details,{'id_item': n.id_item, 'id_unit' : n.id_unit})
              if (row>=0) {
                details[row].qty = n.stok
              }
            }
            this.details = details
            this.submitForm()
          }
        }
      })
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>